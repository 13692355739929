import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import DetailModal from "../component/modal/DetailModal";
import {useNavigate} from "react-router-dom";
import TextModal from "../component/modal/textModal";
import {request} from "../apis/http";
import moment from 'moment';


gsap.registerPlugin(ScrollTrigger);

const Main = () => {
  const today = moment();
  const swiperRef = useRef();
  const swiperRef2 = useRef();
  const container = useRef();
  const container2 = useRef();
  const navigate = useNavigate();
  const [isDetail, setIsDetailPopup] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [detail, setDetail] = useState();
  const [text, setText] = useState();
  const [section1, setSection1] = useState();
  const [cardNewsList, setCardNewsList] = useState();

  let tl;

  const getDetail = async (value) => {
    const { data, status } = await request.get(`/news/detail/${value.id}`);

    if (status === 200) {
      setDetail(data.cardImgs.sort((a,b) => a.orderNum - b.orderNum));
      setIsDetailPopup(!isDetail);
    }
  }

  const openAlertModal = (item) => {
    if(item.id === 10) {
      setText({title: "곧 오픈됩니다.", content: `곧 공개 됩니다.`});

    } else {
      setText({title: "COMING SOON", content: `${Number(item.openDate.split('-')[1])}월에 공개 됩니다.`});
    }
    setIsAlert(!isAlert);
  }

  const goToDetail = (value) => {
    if (value.id === "test") {
      navigate(`/dev-detail`);

    } else {
      navigate(`/detail?id=${value.id}`);

    }
  }

  const goToEnd = () => {
    const list1ItemWidth = document.querySelectorAll('#list .card-slider .item-wrapper')[0].clientWidth;
    const getList1Length = document.querySelectorAll('#list .card-slider .item-wrapper').length;

    if (swiperRef.current && tl) {
      gsap.to('#list .item-wrapper', {x: -((list1ItemWidth * getList1Length) - (window.innerWidth -  (window.innerWidth * 0.18)))});
      setTimeout(() => {
        window.scrollTo(0, tl.vars.scrollTrigger.end.split("+=")[1]);
      }, 500);
    }
  }
  const goToEnd2 = () => {
    const list2ItemWidth = document.querySelectorAll('#list2 .card-slider .item-wrapper')[0].clientWidth;
    const getList2Length = document.querySelectorAll('#list2 .card-slider .item-wrapper').length;
    if (swiperRef2.current && document.querySelector('#list2 .secondAn')) {
      gsap.to('#list2 .item-wrapper', {x: -((list2ItemWidth * getList2Length) - (window.innerWidth - (window.innerWidth * 0.23)))});
    }
  }


  useLayoutEffect(() => {
    if (!swiperRef.current && !container.current) return;
    let ctx = gsap.context(() => {
      tl = gsap.timeline({
        scrollTrigger: {
          pin: true,
          scrub: true,
          trigger: container.current,
          start: 'top top',
          end: `+=${swiperRef.current?.offsetWidth} `,
          invalidateOnRefresh: true,
          anticipatePin: 1,
          onUpdate: (self) => {
            console.log('self::', self);

          }
        },
      });

      let tl2 = gsap.timeline({
        scrollTrigger: {
          pin: true,
          scrub: true,
          trigger: container2.current,
          start: 'top top',
          end: `+=${swiperRef2.current?.offsetWidth}`,
          invalidateOnRefresh: true,
          anticipatePin: 1

        }
      });
      const list1ItemWidth = document.querySelectorAll('#list .card-slider .item-wrapper')[0].clientWidth;
      const getList1Length = document.querySelectorAll('#list .card-slider .item-wrapper').length;

      tl.to('#list .item-wrapper', {x: -((list1ItemWidth * getList1Length) - (window.innerWidth -  (window.innerWidth * 0.18)))}, 0)
        .from("#list .secondAn", {
            duration: 0.1,
            stagger: {
              amount: 0.8
            }
          },
          0
        );

      if (document.querySelector('#list2 .secondAn')) {
        const list2ItemWidth = document.querySelectorAll('#list2 .card-slider .item-wrapper')[0].clientWidth;
        const getList2Length = document.querySelectorAll('#list2 .card-slider .item-wrapper').length;
        console.log('list2ItemWidth:::', list2ItemWidth);
        tl2.to('#list2 .item-wrapper', {x: -((list2ItemWidth * getList2Length) - (window.innerWidth - (window.innerWidth * 0.23)))}, 0)
          .from("#list2 .firstAn", {
              duration: 0.2,
              stagger: {
                amount: 0.8
              }
            },
            0
          );
      }

    },);

    return () => {
      ctx.revert();
    }
  }, [section1]);

  const getData = async () => {
    const [event, cardNewList] = await Promise.all([
      request.get('board/boardlist/event'),
      request.get('news/list')
    ])
    console.log("status::::event::::;", event);
    console.log("status::cardNewList::::::;", cardNewList);

    if (event.status === 200) {
      setSection1(event.data.data);
    }

    if(cardNewList.status === 200) {
      setCardNewsList(cardNewList.data);
    }

  }

  useEffect(() => {
    if (!section1) {
      getData();
    }
  }, [section1]);


  return (
    <div>
      <section className={'section visual'} id={'visual'}>
        <div className="container">
          <div className="divider"/>
          <div className="float-text">
            <p>함께 일군 50년, 함께 만들 100년</p>
            <h2>돌아보다, 나아가다</h2>
            <span>50th Anniversary of Kyungshin</span>
          </div>
          <div className="years-text">
            <div className="t1974"><span>1974</span></div>
            <div className="t2024"><span>2024</span></div>
          </div>
        </div>
        <div className="topper-text">
          <p>
            50년 너머 끝없이 멈추지 않을 우리의 도전, <br/>함께 나누고 나아갈 경신의 자부심
          </p>
          <a href={'/vision'}>READ MORE<img src="/images/arrow-s.png"/></a>
        </div>
        <div className="bg-img">
          <div className="left">
            <div className="screen"/>
          </div>
          <div className="right">
            <div className="screen"/>
          </div>
        </div>
      </section>
      <section className={'section list'} id={'list'} ref={container}>
        <div className="screen"/>
        <div className="container">
          <div className="title">
            <span>PRIDE</span>
            <label>PRIDE</label>
            <h3>대한민국을 대표하는 50년 역사의 주인공, <br/>당신과 함께하는 경신으로</h3>
          </div>
        </div>
        <button className="endline" onClick={goToEnd}>맨끝으로</button>
        <div className="card-slider" ref={swiperRef}>
          {section1 && section1.map((item) => {
            return (
              <div className="item-wrapper" key={`slider1-${item.id}`}
                   onClick={item.isOpen ? () => goToDetail(item) : () => openAlertModal(item)}>
                <a className={`item ${item.id === 0 ? 'firstAn' : 'secondAn'}`}>
                  <div className="thumb">
                    <img src={moment(item.openDate).isBefore(today) ? item.thumbnailImg : item.preThumbnailImg}
                         alt={item.title}/>
                  </div>
                  <p>{moment(item.openDate).isBefore(today) ? item.title : 'COMING SOON'}</p>
                  {
                    item.id !== 10 ? moment(item.openDate).isBefore(today) ?
                      <span>{item.startPeriod === item.endPeriod ? moment(item.startPeriod).format('yyyy.MM.DD') : `${moment(item.startPeriod).format('yyyy.MM.DD')} ~ ${moment(item.endPeriod).format('yyyy.MM.DD')}`}</span>
                      : <span>{Number(item.openDate.split('-')[1])}월에 공개 됩니다.</span> : <span/>
                  }
                </a>
              </div>
            )
          })}
          {/*<div className="item-wrapper" onClick={ () => goToDetail("test")}>*/}
          {/*  <a className={`item secondAn`}>*/}
          {/*    <div className="thumb">*/}
          {/*      <img src={"/images/news03/00_title.png"}*/}
          {/*           alt={"test"}/>*/}
          {/*    </div>*/}
          {/*    <p>COMING SOON</p>*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="item-wrapper">
            <a className="item continue thirdAn">
              <h4>to<br/>be<br/>continued<p>1년간 이어질 경신 가족을 위한 축제는 계속됩니다. </p></h4>
            </a>
          </div>
        </div>
      </section>
      <section className={'section list news'} id={'list2'} ref={container2}>
        <div className="screen"/>
        <div className="container">
          <div className="title">
            <span>CARD NEWS</span>
            <label>CARD NEWS</label>
            <h3>경신인과 함께 만들어가는<br/>생활정보 전해드려요</h3>
          </div>
        </div>
        <button className="endline" onClick={goToEnd2}>맨끝으로</button>
        <div className="card-slider" ref={swiperRef2}>
          {cardNewsList && cardNewsList?.sort((a,b) => {
            return new Date(b.createdDate) - new Date(a.createdDate);
          }).map((item) => {
            return (
              <div className="item-wrapper" key={`slider1-${item.id}`} onClick={() => getDetail(item)}>
                <a className={`item ${item.id === 0 ? 'firstAn' : 'secondAn'}`}>
                  <div className="thumb">
                    <img src={item.thumbnailImg} alt={item.title}/>
                  </div>
                  <p>{item.title}</p>
                  <span>{item.createdDate}</span>
                </a>
              </div>
            )
          })}
        </div>
      </section>
      {isAlert && text && <TextModal setOpen={() => setIsAlert(false)} title={text.title} content={text.content}/>}
      {isDetail && detail && <DetailModal setOpen={() => setIsDetailPopup(false)} detail={detail}/>}
    </div>
  )
}

export default Main;
